import type { AppProps } from 'next/app'

// NProgress
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

// React
import { useEffect } from 'react'

// Next.js
import Script from 'next/script'
import Router from 'next/router'

// Styled Components
import { ThemeProvider } from 'styled-components'
import GlobalStyle from '../styled-components/globalStyles'
import { theme } from '../styled-components/theme'

// Apollo
import { ApolloProvider } from '@apollo/client'
import client from '../utils/apollo-client'

function MyApp({ Component, pageProps }: AppProps) {
  useEffect(() => {
    Router.events.on('routeChangeStart', NProgress.start)
    Router.events.on('routeChangeComplete', NProgress.done)
    Router.events.on('routeChangeError', NProgress.done)
    return () => {
      Router.events.off('routeChangeStart', NProgress.start)
      Router.events.off('routeChangeComplete', NProgress.done)
      Router.events.off('routeChangeError', NProgress.done)
    }
  }, [])

  return (
    <>
      {process.env.NEXT_PUBLIC_GTM_ID && (
        <>
          <Script
            id='gtm-data-layer'
            strategy='afterInteractive'
          >{`window.dataLayer = window.dataLayer || [];`}</Script>
          <Script
            id='google-tag-manager'
            strategy='afterInteractive'
          >{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID}');`}</Script>
        </>
      )}
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <>
            <GlobalStyle />
            <Component {...pageProps} />
          </>
        </ThemeProvider>
      </ApolloProvider>
    </>
  )
}

export default MyApp
