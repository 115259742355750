import { ApolloClient, InMemoryCache } from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'

const token = process.env.NEXT_PUBLIC_API_TOKEN

const client = new ApolloClient({
  //@ts-ignore
  link: createUploadLink({
    uri: process.env.NEXT_PUBLIC_GRAPHQL_URL,
    headers: {
      Authorization: token ? `Bearer ${token}` : '',
    },
  }),
  cache: new InMemoryCache(),
  // Delete code below if not using ISR
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache',
    },
  },
})

export default client
