import { createGlobalStyle } from 'styled-components'
import { theme } from './theme'

export type Props = {
  theme: typeof theme
}
const GlobalStyle = createGlobalStyle<Props>`

/* CSS RESET START */

/*
 Use a more-intuitive box-sizing model.
*/
*, *::before, *::after {
  box-sizing: border-box;
}

/*
 Remove default margin
*/
* {
  margin: 0;
}

/*
 Remove default padding and font-weight
*/
body, h1, h2, h3, h4, h5, h6, p, small, ol, ul {
  padding: 0;
  font-weight: normal;
}

/*
 Allow percentage-based heights in the application
*/
html, body {
  height: 100%;
}

/*
  Typographic tweaks - add accessible line-height & improve text rendering
*/
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

/*
 Improve media defaults
*/
img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}

/*
 Remove built-in form typography styles
*/
input, button, textarea, select {
  font: inherit;
}

/*
 Avoid text overflows
*/
p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

/* Remove list styles (bullets/numbers) */
ol, ul, menu {
	list-style: none;
}

/*
 Create a root stacking context
*/
#__next {
  isolation: isolate;
  height: 100%;
}

/* CSS RESET END */


/* CUSTOM GLOBAL STYLES START */

/* @font-face {
  font-family: 'Helvetica Neue LT Pro';
  src: url("/fonts/HelveticaNeueLTPro-Th.otf") format("opentype");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
font-family: 'Helvetica Neue LT Pro';
  src: url("/fonts/HelveticaNeueLTPro-Lt.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue LT Pro';
  src: url("/fonts/HelveticaNeueLTPro-Roman.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  descent-override: 0%;
}

@font-face {
  font-family: 'Helvetica Neue LT Pro';
  src: url("/fonts/HelveticaNeueLTPro-Md.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue LT Pro';
  src: url("/fonts/HelveticaNeueLTPro-Bd.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
} */

html {
  scroll-behavior: smooth;
}

body {
  /* font-family: 'Helvetica Neue LT Pro', sans-serif; */
  font-family: "neue-haas-grotesk-text", sans-serif;
  color: ${({ theme }) => theme.colors.body};
  font-size: ${({ theme }) => (16 - 2) / theme.fontSizes.root + 'rem'};

  @media (${({ theme }) => theme.mediaQueries.lg}) {
    font-size: ${({ theme }) => (18 - 2) / theme.fontSizes.root + 'rem'};
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: "neue-haas-grotesk-display", sans-serif;
  line-height: 1.2;
  color: ${({ theme }) => theme.colors.grey120};
}

a {
  color: ${({ theme }) => theme.colors.link};

  &:hover {
    color: ${({ theme }) => theme.colors.linkHover};
    text-decoration: underline;
  }
}

table, td, th {
  border: 1px solid ${({ theme }) => theme.colors.red100};
}

th {
  font-weight: 700;
}

td, th {
  padding: 0.5rem;
}

table {
  width: 100%;
  border-collapse: collapse;
}

/* Remove @artsy/fresnel div box -> replaced by their pseudo-box and their child boxes */
.fresnel-container {
    display: contents;
}

figure.media {
    position: relative;
    width: 100%;
    overflow: hidden;
    aspect-ratio: 16 / 9;
    margin: 32px auto;

    iframe {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        border: none;
    }
  }

#nprogress .bar {
background: ${({ theme }) => theme.colors.progressBar} !important;
}

/* CUSTOM GLOBAL STYLES END */

`

export default GlobalStyle
