import { BREAKPOINTS } from '../utils/constants'
import { COLORS } from '../utils/constants'
import { GRADIENTS } from '../utils/constants'

export const theme = {
  colors: {
    body: COLORS.grey110,
    backgroundWhite: COLORS.white,
    backgroundGrey: COLORS.grey80,
    link: COLORS.red100,
    linkHover: COLORS.red110,
    divider: COLORS.grey90,
    progressBar: COLORS.red100,
    button: {
      contained: {
        text: COLORS.white,
        textHover: COLORS.white,
        border: COLORS.transparent,
        background: COLORS.red100,
        backgroundHover: GRADIENTS.red100ToRed120,
        backgroundActive: GRADIENTS.red100ToRed120,
      },
      outlined: {
        text: COLORS.red100,
        textHover: COLORS.white,
        textActive: COLORS.white,
        border: COLORS.red100,
        background: COLORS.transparent,
        backgroundHover: COLORS.red100,
        backgroundActive: GRADIENTS.red100ToRed120,
      },
      outlinedSecondary: {
        text: COLORS.white,
        border: COLORS.white,
        background: COLORS.transparent,
        backgroundHover: 'rgba(255, 255, 255, 0.1)',
        backgroundActive: 'rgba(0, 0, 0, 0.13)',
      },
    },
    scrollButton: {
      border: COLORS.white,
      icon: COLORS.white,
      background: COLORS.transparent,
      backgroundHover: COLORS.whiteOpacity28,
      backgroundActive: COLORS.blackOpacity13,
    },
    articleCard: {
      background: COLORS.white,
    },
    textBlock: {
      title: COLORS.grey120,
      text: COLORS.grey110,
    },
    offerCard: {
      text: COLORS.grey110,
      textBoxBackground: COLORS.grey80,
    },
    aboutCard: {
      title: COLORS.grey120,
      text: COLORS.grey110,
      contentBackground: COLORS.white,
    },
    tips: {
      tipBackground: COLORS.white,
      tipNumber: COLORS.red100,
      tipText: COLORS.grey110,
    },
    timeline: {
      nodeBackground: COLORS.red100,
      nodeText: COLORS.red100,
    },
    mainNavbar: {
      borderDarkBg: COLORS.white,
      borderLightBg: COLORS.grey100,
      backgroundDarkBg: COLORS.transparent,
      backgroundLightBg: COLORS.whiteOpacity70,
      navList: {
        item: {
          textLightBg: COLORS.grey120,
          textDarkBg: COLORS.white,
          border: COLORS.transparent,
          borderHover: COLORS.red100,
        },
      },
    },
    submenu: {
      background: COLORS.white,
      border: COLORS.grey85,
      title: COLORS.red100,
      item: COLORS.grey110,
      itemHover: COLORS.grey120,
      itemActive: COLORS.black,
    },
    hamburgerMenu: {
      background: COLORS.white,
      languageSwitcher: {
        background: COLORS.transparent,
        text: COLORS.red100,
      },
      navList: {
        item: {
          text: COLORS.red100,
          border: COLORS.grey90,
        },
        subItem: {
          text: COLORS.grey110,
          border: COLORS.grey90,
          backLink: {
            text: COLORS.red100,
          },
        },
      },
    },
    topNavbar: {
      iconLightBg: COLORS.grey120,
      iconDarkBg: COLORS.white,
      iconHover: COLORS.red100,
      navItemLightBg: COLORS.grey120,
      navItemDarkBg: COLORS.white,
      navItemHoverLightBg: COLORS.grey120,
      navItemHoverDarkBg: COLORS.white,
      navItemBorder: COLORS.transparent,
      navItemBorderHoverLightBg: COLORS.grey120,
      navItemBorderHoverDarkBg: COLORS.white,
    },
    breadcrumb: {
      link: COLORS.grey100,
      linkHover: COLORS.red100,
    },
    phoneContact: {
      text: COLORS.red100,
      textHover: COLORS.red100,
    },
    contact: {
      icon: {
        arrowHover: COLORS.white,
        backgroundHover: COLORS.red100,
      },
    },
    burgerIcon: {
      white: COLORS.white,
      red: COLORS.red100,
    },
    roundCard: {
      backgroundWhite: COLORS.white,
      backgroundGrey: COLORS.grey80,
      text: COLORS.grey110,
    },
    search: {
      backgroundClosedLightBg: COLORS.red100,
      backgroundOpenLightBg: COLORS.white,
      borderLightBg: COLORS.red100,
      borderDarkBg: COLORS.transparent,
      iconClosedLightBg: COLORS.white,
      iconOpenLightBg: COLORS.red100,
      iconClosedDarkBg: COLORS.grey110,
      iconOpenDarkBg: COLORS.grey110,
      textLightBg: COLORS.red100,
      textPlaceholderLightBg: COLORS.grey90,
      textDarkBg: COLORS.grey110,
      textPlaceholderDarkBg: COLORS.grey90,
    },
    hero: {
      title: COLORS.white,
      text: COLORS.white,
    },
    downloadItem: {
      border: COLORS.grey100,
      text: COLORS.grey110,
      textHover: COLORS.red100,
      icon: {
        arrowHover: COLORS.white,
        backgroundHover: COLORS.red100,
      },
    },
    pressRelease: {
      border: COLORS.grey100,
    },
    service: {
      border: COLORS.grey90,
      header: COLORS.red100,
    },
    activityCard: {
      background: COLORS.red100,
      text: COLORS.white,
    },
    contactForm: {
      input: {
        text: COLORS.grey120,
        errorText: COLORS.red100,
        placeholder: COLORS.grey110,
        background: COLORS.grey80,
        backgroundPromo: COLORS.white,
      },
    },
    tabs: {
      backgroundGrey: COLORS.grey80,
      backgroundWhite: COLORS.white,
      line: COLORS.red100,
      text: COLORS.red100,
    },
    establishmentCard: {
      title: COLORS.grey120,
      border: COLORS.grey90,
      borderHover: COLORS.grey120,
    },
    languageSwitcher: {
      button: {
        background: COLORS.transparent,
        backgroundHover: COLORS.grey90,
        textLightBg: COLORS.grey120,
        textLightBgHover: COLORS.grey120,
        textDarkBg: COLORS.white,
        textDarkBgHover: COLORS.white,
      },
      list: {
        background: COLORS.white,
        item: {
          backgroundHover: COLORS.grey80,
        },
      },
    },
    socialIconsBar: {
      icon: {
        colorHover: COLORS.white,
        colorActive: COLORS.white,
        background: COLORS.transparent,
        backgroundHover: COLORS.red100,
        backgroundActive: GRADIENTS.red100ToRed120,
      },
    },
    textBox: {
      background: COLORS.white,
      text: COLORS.grey110,
      title: COLORS.grey120,
    },
    footer: {
      border: COLORS.grey90,
      textHelpline: COLORS.red100,
      textLink: COLORS.grey120,
      textCopyMobile: COLORS.grey120,
      textCopyDesktop: COLORS.red100,
    },
    pageTitle: {
      text: COLORS.grey120,
    },
    title: {
      red: COLORS.red100,
      grey: COLORS.grey120,
    },
    regionSelect: {
      text: COLORS.grey110,
      border: COLORS.grey90opacity39,
      background: COLORS.grey85,
      borderHover: COLORS.grey105,
      backgroundHover: COLORS.grey100opacity39,
      borderActive: COLORS.grey95,
      backgroundActive: COLORS.grey100,
      borderDarkBg: COLORS.white,
      borderHoverDarkBg: COLORS.grey90,
      borderActiveDarkBg: COLORS.grey110,
      backgroundDarkBg: COLORS.whiteOpacity39,
      backgroundHoverDarkBg: COLORS.whiteOpacity79,
      backgroundActiveDarkBg: COLORS.grey85opacity50,
      textDarkBg: COLORS.white,
      textHoverDarkBg: COLORS.grey120,
      textActiveDarkBg: COLORS.white,
    },
    contactRegionSelect: {
      border: COLORS.red100,
      background: COLORS.transparent,
      backgroundHover: COLORS.red100,
      text: COLORS.red100,
      textHover: COLORS.white,
      dropdown: {
        background: COLORS.white,
        text: COLORS.grey110,
        item: {
          background: COLORS.white,
          backgroundHover: COLORS.grey80,
          backgroundActive: COLORS.grey90,
        },
      },
    },
    ...COLORS,
  },
  gradients: {
    ...GRADIENTS,
  },
  fontSizes: {
    root: 16,
  },
  mediaQueries: {
    xs: `min-width: ${BREAKPOINTS.xs}px`,
    sm: `min-width: ${BREAKPOINTS.sm}px`,
    md: `min-width: ${BREAKPOINTS.md}px`,
    lg: `min-width: ${BREAKPOINTS.lg}px`,
    xl: `min-width: ${BREAKPOINTS.xl}px`,
    xxl: `min-width: ${BREAKPOINTS.xxl}px`,
    xxxl: `min-width: ${BREAKPOINTS.xxxl}px`,
    xsMax: `max-width: ${BREAKPOINTS.xs - 1}px`,
    smMax: `max-width: ${BREAKPOINTS.sm - 1}px`,
    mdMax: `max-width: ${BREAKPOINTS.md - 1}px`,
    lgMax: `max-width: ${BREAKPOINTS.lg - 1}px`,
    xlMax: `max-width: ${BREAKPOINTS.xl - 1}px`,
    xxlMax: `max-width: ${BREAKPOINTS.xxl - 1}px`,
    xxxlMax: `max-width: ${BREAKPOINTS.xxxl - 1}px`,
  },
}
